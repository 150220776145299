export const ProjectCard = ({title, description, imgUrl}) => {
    return (
        <div className="item">
            <div className="proj-imgbx">
                <img src={imgUrl}/>
                <span className="proj-txtx">{description}</span>
            </div>
            <h5>{title}</h5>
        </div>
    )
}