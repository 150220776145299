import { Col, Container, Row } from "react-bootstrap";

export const Banner = ({ language }) => {
  const bannerText = {
    tagLine: language === 'CZ' ? 'Roman Vaněk' : 'Roman Vaněk',
    title: language === 'CZ' ? 'Vývoj firmwaru a hardwaru' : 'Firmware and Hardware Development',
    description: language === 'CZ' ? 'Zabívám se vývojem firmwaru a hardwaru pro vestavěné systémy. Nabízím kompletní návrh jednoúčelových elektronických zařízení' : 'I specialize in firmware and hardware development for embedded systems. I offer complete design of dedicated electronic devices',
    button: language === 'CZ' ? 'Nezávazná poptávka' : 'Request a Quote',
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} ms={6} xl={6}>
            <h1>{bannerText.title}</h1>
            <p>{bannerText.description}</p>
            <span className="navbar-text">
              <button onClick={() => console.log('connect')}>{bannerText.button}</button>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
