import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import avaterR from "../assets/img/avatarR.jpg";

export const About = ({ language }) => {
  const aboutText = {
    title: language === 'CZ' ? 'Vývojář Roman Vaněk' : 'Developer Roman Vaněk',
    description: language === 'CZ' 
      ? [
            "Zabývám se programováním a navrhováním elektroniky. Moje práce mě baví, naplňuje a mám ji jako svůj koníček. Každý nový projekt je pro mě skvělou příležitostí jak získávat další zkušenosti a zároveň se v tomto směru zdokonalovat. Věnuji se každé fázi vývoje elektroniky, od koncepce přes návrh desky plošných spojů až po naprogramování mikrokontroleru.",
            "",
            "V zakázkách používám mikrokontrolery řady STM32. Můj toolchain spočívá v gcc kompileru a gdb debuggeru. Jako IDE používám buď STMcubeIDE nebo VSCode spolupracujícím s STM32CubeMX. Mám zároveň zkušenosti s programováním bez HAL knihoven.",
            "",
            "V současné době zároveň studuji na fakultě elektrotechniky a komunikačních technologií na Vysokém učení technickém v Brně.",
            "",
            "",
            "",
            "<strong>roman.vanek@rvembedded.cz</strong>"
        ]
      : [
            "I specialize in programming and designing electronics. My work is my passion, it fulfills me, and I enjoy it as my hobby. Each new project is a great opportunity for me to gain more experience and improve in this field. I am involved in every phase of electronics development, from concept through PCB design to microcontroller programming.",
            "",
            "For projects, I use STM32 microcontrollers. My toolchain consists of the gcc compiler and gdb debugger. As an IDE, I use either STMcubeIDE or VSCode collaborating with STM32CubeMX. I also have experience programming without HAL libraries.",
            "",
            "Currently, I am also studying at the Faculty of Electrical Engineering and Communication Technology at Brno University of Technology.",
            "",
            "",
            "",
            "<strong>roman.vanek@rvembedded.cz</strong>"
        ],
  };

  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col xs={7} ms={6} xl={6}>
            <img src={avaterR} alt="Header IMG" />
          </Col>
          <Col xs={12} ms={6} xl={6}>
            <h1>{aboutText.title}</h1>
            <p>
              {aboutText.description.map((line, index) => (
                <React.Fragment key={index}>
                  <span dangerouslySetInnerHTML={{ __html: line }} /><br/>
                </React.Fragment>
              ))}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}