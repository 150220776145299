import './App.css';
import { useState } from "react";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { About } from "./components/About";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [language, setLanguage] = useState('CZ'); // Initialize language state

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'CZ' ? 'EN' : 'CZ'));
  }

  return (
    <div className="App">
      <NavBar language={language} toggleLanguage={toggleLanguage} />
      <Banner language={language} />
      <About language={language} />
      <Projects language={language} />
      <Footer language={language} />
    </div>
  );
}

export default App;