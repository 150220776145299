import { Col, Container, Row } from "react-bootstrap";

export const Footer = ({ language }) => {
  const footerText = {
    owner: language === 'CZ' ? 'Roman Vaněk IČ 17244781' : 'Roman Vaněk IČ 17244781',
    address: language === 'CZ' ? 'Václavice 237 Hrádek nad Nisou 463 34' : 'Václavice 237 Hrádek nad Nisou 463 34',
    registration: language === 'CZ' ? 'Zapsán v živnostenském rejstříku Magistrátu města Liberec, SZ CJ MML 133460/22' : 'Registered in the trade register of the Liberec Municipality, SZ CJ MML 133460/22',
  }

  return (
    <section className="footer" id="None">
      <Container>
        <Row className="align-items-center text-center">
          <Col xs={6} md={3} className="d-flex justify-content-center align-items-center">
            <p>{footerText.owner}</p>
          </Col>
          <Col xs={6} md={3} className="d-flex justify-content-center align-items-center">
            <p>{footerText.address}</p>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
            <p>{footerText.registration}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}